.section {
  padding: 20px;
}

.price-category-heading {
  font-size: 1.5em;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f7cac9;
  color: black;
  border-radius: 5px;
}

.price-category-heading:hover {
  background-color: #e2e6ea;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  transition: max-height 0.3s ease-out;
  -webkit-transition: max-height 0.3s ease-out;
  overflow: hidden;
}

ul.collapsed {
  max-height: 0;
  visibility: hidden;
}

ul.active {
  max-height: 500px;
  visibility: visible;
}

ul li {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  margin: 10px 0;
  padding-left: 10px;
}

ul li span.price {
  font-weight: bold;
}

/* Image Preview */
.image-preview {
  margin-top: 20px;
  text-align: center;
}

.preview-image {
  max-width: 50%;
  cursor: pointer;
  transition: transform 0.2s;
  -webkit-transition: -webkit-transform 0.2s;
}

.preview-image:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}

/* Modal styling for fullscreen image */
.modal {
  display: flex;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  justify-content: center;
  align-items: center;
}

.modal-content {
  max-width: 80%;
  max-height: 80%;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
}
