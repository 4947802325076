/* Allgemeines Styling für die Sektion */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  -webkit-flex-direction: column;
  -webkit-align-items: center;
  -webkit-justify-content: center;
}

/* Styling für das Portrait-Bild */
.about-portrait {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Styling für den Textbereich */
.about-text {
  max-width: 800px;
  text-align: left;
  padding: 10px 20px;
}

.about-text h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.about-text p {
  font-size: 18px;
  line-height: 1.6;
  color: #f6f3f3;
  margin-bottom: 10px;
}

/* Mobile-Optimierung */
@media (max-width: 768px) {
  .about-text {
      text-align: center;
      padding: 10px;
  }

  .about-portrait {
      width: 120px;
      height: 120px;
  }

  .about-text h2 {
      font-size: 22px;
  }

  .about-text p {
      font-size: 18px;
      /* font-weight: 400; */
  }
}
