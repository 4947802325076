/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Great+Vibes&display=swap'); /* Added handwritten font */

/* Styling for the logo */
.header-logo {
  font-family: 'Playfair Display', serif; /* Serif font for the studio name */
  color: #fdfcfb;
  font-size: 1.8rem;
  text-align: center;
}

.studio-name {
  font-weight: 700; /* Bold for the studio name */
}

.logo-image {
  width: 80px;
  height: auto;
  margin-bottom: -5px; /* Ensure logo does not overflow the pink line */
}

.header-content {
  display: flex;
  align-items: center;
}

.header-logo {
  flex-shrink: 0; /* Prevent the logo from shrinking */
  margin-right: 20px;
  position: relative;
}

.header-text {
  color: #fdfcfb;
  display: flex;
  flex-direction: column;
}

.studio-title {
  font-family: 'Playfair Display', serif; /* Normal serif font for "Kosmetikstudio" */
  font-size: 1.1rem;
  font-style: italic;
  /* font-weight: i; */
}

.owner-name {
  font-family: 'Great Vibes', cursive; /* Handwritten font for the owner's name */
  font-weight: 400; /* Light font weight for a fine look */
  font-size: 1.4rem;
  color: white;
}

/* Full-screen navigation menu */
.nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: #f7cac9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s linear 0.3s;
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease, visibility 0s linear 0.3s;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

/* Open menu state */
.nav.open {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s;
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease, visibility 0s;
}

/* Common style for both hamburger and close icon */
.header-menu, .nav-close {
  position: absolute;
  top: 10px; /* Adjusted for better mobile usability */
  right: 15px;
  font-size: 2.5rem; /* Reduced size for mobile optimization */
  color: #fdfcfb;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  opacity: 1;
}

/* When the nav closes, hide the X icon */
.nav:not(.open) .nav-close {
  opacity: 0;
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
}

/* Menu items */
.nav-item {
  margin: 20px 0;
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  text-transform: uppercase;
  color: #333333;
  text-align: center; /* Centered for better mobile experience */
}

.nav-item a {
  color: inherit;
  text-decoration: none;
  font-weight: 400;
}

.nav-item a:hover {
  color: #ffffff;
  transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
}

/* Hide body overflow when the menu is open */
body.menu-open {
  overflow: hidden;
}

/* Mobile-optimizations */
@media (max-width: 768px) {
  .header-logo {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .logo-image {
    width: 60px;
  }

  .header-menu, .nav-close {
    font-size: 2.2rem; /* Reduce the icon size for smaller screens */
  }

  .nav-item {
    font-size: 1.6rem; /* Adjust font size for smaller screens */
    margin: 15px 0;
  }
}
