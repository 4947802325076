/* Styling for the map container */
.map-container {
    margin: 20px 0;
}

/* Styling for the gallery */
.gallery {
    text-align: center;
    margin-top: 20px;
}

.gallery h2 {
    font-size: 24px;
    margin-bottom: 15px;
}

/* Styling for the thumbnails */
.thumbnails {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid #ddd;
    transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
    -webkit-transition: -webkit-transform 0.2s ease-in-out, border 0.2s ease-in-out;
}

.thumbnail:hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    border-color: #555;
}

/* Modal styling */
.modal {
    display: flex;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
}

.modal-content {
    max-width: 80%;
    max-height: 80%;
    margin: auto;
    display: block;
    border: 5px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

/* Close button */
.close {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* Navigation buttons for the modal */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  color: #fff;
  font-size: 24px; /* Adjusted button size */
  font-weight: bold;
  padding: 10px 20px; /* Added padding for better design */
  transition: 0.3s;
  -webkit-transition: 0.3s;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.6); /* Background for better visibility */
  border: none;
  border-radius: 5px; /* Rounded corners for a cleaner look */
}

.prev {
  left: 5%; /* Reduced the positioning to make the buttons less intrusive */
}

.next {
  right: 5%;
}

.prev:hover, .next:hover {
  color: #bbb;
  background-color: rgba(0, 0, 0, 0.8); /* Darker background on hover */
}

/* Caption text */
.caption {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  color: #ccc;
  font-size: 18px;
  padding: 10px;
  text-align: center;
}