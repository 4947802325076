// Variables for reuse
$primary-color: #d268c1;
$light-color: #fdfcfb;
$dark-color: #333333;
$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

// General body and container styling
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: $light-color;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  overflow-x: hidden;
}

html, body {
  // Removed scroll-snap-type for better control
  // scroll-snap-type: y mandatory;
  // -webkit-scroll-snap-type: y mandatory;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 80px);  // Adjusted to account for the header
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #151414;
  position: fixed;
  top: 0;
  z-index: 2000;
  border-bottom: 5px solid $primary-color;
  opacity: 1;

  .header-logo {
    font-family: 'Playfair Display', serif;
    font-size: 1.8rem;
    color: $light-color;
    font-weight: bold;
    padding-left: 20px;
  }
}

.content {
  // padding-top: 80px;  // To avoid overlap with the fixed header
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  // Removed scroll-snap-align to avoid conflicts
  // scroll-snap-align: start;
  // -webkit-scroll-snap-align: start;
  height: 110vh;  // Adjusted height to account for header
  overflow: hidden;

  &:nth-child(even) {
    background-color: $dark-color;
    color: $light-color;
  }

  &:nth-child(odd) {
    background-color: $light-color;
    color: $dark-color;

    h1 {
      color: $dark-color;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    }
  }

  .content-image {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
}

// Sliding images container
.sliding-image-container {
  width: 100%;
  overflow: hidden;
  position: relative;

  .sliding-images {
    display: flex;
    width: 200%;
    animation: slide 10s linear infinite;
    -webkit-animation: slide 10s linear infinite;

    .image {
      flex: 1 0 25%;
      background-size: cover;
      background-position: center;
      height: 300px;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  100% {
    transform: translateX(-75%);
    -webkit-transform: translateX(-75%);
  }
}

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-75%);
  }
}

.content-text {
  font-family: 'Lato', sans-serif;
  font-size: 1.1rem;
  padding: 10px;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.2rem;
  margin: 20px 0;
  color: #ffcc99;
  font-weight: 400;
}

// Media Queries for mobile responsiveness
@media (max-width: 768px) {
  .header-logo {
    font-size: 1.5rem;
  }

  .content {
    margin: 10px auto;

    .image {
      height: 200px;
    }

    h1 {
      font-size: 1.8rem;
    }

    .button {
      width: 280px;
      font-size: 1rem;
    }
  }
}

// Button Styling
.button {
  background-color: transparent;
  width: 350px;
  letter-spacing: 0.1rem;
  color: $light-color;
  padding: 10px 20px;
  border: 5px solid $primary-color;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  -webkit-transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  margin-top: 20px;

  &:hover {
    background-color: $primary-color;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }

  &:active {
    background-color: rgba($primary-color, 0.6);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

// Styling for the down arrow
.scroll-arrow {
  font-size: 2rem;
  color: $light-color;
  cursor: pointer;
  margin-top: 20px;
  transition: color 0.3s ease, transform 0.3s ease;
  -webkit-transition: color 0.3s ease, -webkit-transform 0.3s ease;

  &:hover {
    color: $primary-color;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
}

// Modal gallery styling for images
.modal {
  display: flex;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  // height: calc(100vh - 80px);  // Adjusted for the header height
  background-color: rgba(0, 0, 0, 0.9);
  justify-content: center;
  align-items: center;

  .modal-content {
    max-width: 80%;
    max-height: 80%;
    margin: auto;
    display: block;
    border: 5px solid #fff;
    box-shadow: $box-shadow;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;

    &:hover, &:focus {
      color: #bbb;
    }
  }

  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    user-select: none;

    &:hover {
      color: #bbb;
    }
  }

  .prev {
    left: 10%;
  }

  .next {
    right: 10%;
  }

  .caption {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    color: #ccc;
    font-size: 18px;
    padding: 10px;
    text-align: center;
  }
}
